import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import NewLayout from "../components/new_layout"


const Standards = ({ data, pageContext }) => {
    const page = data.page.edges[0].node

    return (
        <NewLayout pageContext={pageContext}>
            <Seo lang={pageContext.langKey} title={page.seo.title} description={page.seo.meta_description} translates={pageContext.translates} />

            <main className="main">
                <div className="main__section main__section--100" >
                    <div className="container">
                        <h1 className="merriweather">{page.title}</h1>
                        <div className="container__content">
                            <GatsbyImage alt={page.image.alternativeText} image={getImage(page.image.localFile)} />
                            <ReactMarkdown children={page.description} rehypePlugins={[rehypeRaw]}/>
                            <ReactMarkdown children={page.details} rehypePlugins={[rehypeRaw]}/>
                        </div>
                    </div>
                </div>
            </main>
        </NewLayout>
    )
}

export default Standards

export const standardsPageQuery = graphql`
query ($langKey: String!) {
    page: allStrapiPoliticaDeCalidad ( filter: { locale: { eq: $langKey } } ) {
        edges {
            node {
                seo {
                    title,
                    meta_description
                }
                title
                description
                details
                logos_outbound_link
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 1440
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
                logos {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 640
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
        }
    }
}
`
